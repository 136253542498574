<template>
  <v-container id="data-tables" fluid tag="section">
    <v-row class="mt-3">
      <v-col
        cols="12"
        :sm="isUsaFootball ? '4' : '6'"
        :md="isUsaFootball ? '4' : '6'"
      >
        <h1 class="pt-2">Dashboard</h1>
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        :sm="isUsaFootball ? '8' : '6'"
        :md="isUsaFootball ? '8' : '6'"
      >
        <div class="d-flex">
          <div class="custom-col1">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="customFilter"
                  :items="filters"
                  label="Select"
                  solo
                  dense
                  item-text="label"
                  item-value="value"
                  hide-details
                  @change="selectFilter"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  ref="menu"
                  v-model="datePicker"
                  :close-on-content-click="false"
                  :return-value.sync="dates"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="Date Range"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      dense
                      solo
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dates" range no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="datePicker = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="filterByDate">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </div>
          <div
            :class="!isUsaFootball ? 'custom-col2' : ''"
            class="d-flex align-center justify-center ml-5"
          >
            <v-menu v-if="isUsaFootball" open-on-hover offset-y class="mx-auto">
              <template #activator="{ on, attrs }">
                <v-btn color="primary" rounded dark v-bind="attrs" v-on="on">
                  Download
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <export-data
                    use-button-text
                    button-text="Activation data"
                    :items-new="exportSignUp"
                    :items-active="exportSignIn"
                    :dates="dates"
                    :total-signed-up="totalSignedUp"
                    :total-signed-in="totalSignedIn"
                    :total-active-user-count="totalActiveUserCount"
                    :average-weekly-user-count="averageWeeklyUserCount"
                  />
                </v-list-item>
                <v-list-item>
                  <v-btn
                    text
                    class="ma-0 pull-right"
                    :disabled="disableButton"
                    @click="getUsaUsersData()"
                  >
                    User Data
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <export-team
                    use-button-text
                    button-text="Team Data"
                    :fields="fields"
                    :json-data="teamData"
                    :headers="teamHeaders"
                  />
                </v-list-item>
              </v-list>
            </v-menu>

            <div v-else>
              <export-data
                :items-new="exportSignUp"
                :items-active="exportSignIn"
                :dates="dates"
                :total-signed-up="totalSignedUp"
                :total-signed-in="totalSignedIn"
                :total-active-user-count="totalActiveUserCount"
                :average-weekly-user-count="averageWeeklyUserCount"
              />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-card class="my-0">
          <v-card-subtitle class="pb-0 font-weight-medium">
            <v-row>
              <v-col cols="7">{{ signupText }}</v-col>
              <v-col v-if="isUsaFootball" cols="5" justify="end" class="pb-0">
                <v-select
                  v-model="customUsers"
                  class="mt-0"
                  :items="userOptions"
                  label="All Sources"
                  solo
                  dense
                  item-text="label"
                  hide-details
                  return-object
                  @change="getUserData"
                />
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-title class="text-h3 font-weight-bold pt-0 black--text">
            {{ totalSignedUp }}
          </v-card-title>
          <v-card-text>
            <apexchart
              v-if="seriesSignedUp[0].data.length > 0"
              height="150"
              type="line"
              :options="optionsSignedUp"
              :series="seriesSignedUp"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="my-0">
          <v-card-subtitle class="pb-0 font-weight-medium">
            Active Users
          </v-card-subtitle>
          <v-card-title class="text-h3 font-weight-bold pt-0 black--text">
            {{ totalActiveUserCount }}
          </v-card-title>
          <v-card-text>
            <apexchart
              v-if="seriesSignIn[0].data.length > 0"
              height="156"
              type="line"
              :options="optionsSignIn"
              :series="seriesSignIn"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="my-0">
          <v-card-subtitle class="pb-0 font-weight-medium">
            Number of Content Items
          </v-card-subtitle>
          <v-card-text>
            <apexchart
              v-if="seriesContent.length > 0"
              height="200"
              type="donut"
              :options="optionsContent"
              :series="seriesContent"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-row>
          <v-col cols="12" sm="6">
            <v-card class="my-0" height="114">
              <v-card-subtitle class="pb-1 font-weight-medium">
                TOTAL
              </v-card-subtitle>
              <v-card-text>
                <h2 class="text-h1 font-weight-bold">
                  {{ drillCount + skillCount + planCount }}
                </h2>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card class="my-0" height="114">
              <v-card-subtitle class="pb-1 font-weight-medium">
                Drills
              </v-card-subtitle>
              <v-card-text>
                <h2 class="text-h1 font-weight-bold">{{ drillCount }}</h2>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card class="my-0" height="114">
              <v-card-subtitle class="pb-1 font-weight-medium">
                Skills
              </v-card-subtitle>
              <v-card-text>
                <h2 class="text-h1 font-weight-bold">{{ skillCount }}</h2>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card class="my-0" height="114">
              <v-card-subtitle class="pb-1 font-weight-medium">
                Plans
              </v-card-subtitle>
              <v-card-text>
                <h2 class="text-h1 font-weight-bold">{{ planCount }}</h2>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="6" sm="8" md="9" class="pa-0">
        <v-tabs
          color="primary"
          background-color="transparent"
          class="customTab"
        >
          <v-tab class="text-capitalize" @click="getItems('drills', order)">
            Drills
          </v-tab>
          <v-tab class="text-capitalize" @click="getItems('plans', order)">
            Plans
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="6" sm="4" md="3" class="pa-0 pr-3">
        <v-select
          v-model="order"
          :items="sortItems"
          label="Sort By"
          solo
          dense
          item-text="label"
          item-value="value"
          hide-details
          @change="getItems(type)"
        />
      </v-col>
      <v-col cols="12">
        <v-card class="my-0">
          <v-card-text>
            <drill-plan :items="items" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ExportData from '@/components/ExportData.vue';
import ExportTeam from '@/components/ExportTeam.vue';
import DrillPlan from './drill_plan.vue';
import { dashboardApi } from '@/plugins/axios';
import moment from 'moment';
import { mapState } from 'vuex';
import { organizationsCollection, usaApp } from '@/utils/firebase';
import generic from '@/mixins/generic.js';
import { saveAs } from 'file-saver';

let today = moment().format('YYYY-MM-DD');
let startOfMonth = moment().subtract('7', 'days').format('YYYY-MM-DD');

export default {
  name: 'DashboardIndex',
  components: {
    DrillPlan,
    ExportData,
    ExportTeam,
  },
  mixins: [generic],
  data: () => ({
    seriesSignedUp: [
      {
        name: 'Count',
        data: [],
      },
    ],
    optionsSignedUp: {
      chart: {
        height: 200,
        type: 'line',
        group: 'social',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
        type: 'datetime',
      },
    },
    seriesSignIn: [
      {
        name: 'Count',
        data: [],
      },
    ],
    optionsSignIn: {
      chart: {
        height: 200,
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: 'smooth',
      },
      xaxis: {
        categories: [],
        type: 'datetime',
      },
    },
    optionsContent: {
      chart: {
        type: 'donut',
        height: 200,
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: ['Drills', 'Skills', 'Plans'],
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true,
              },
            },
          },
        },
      },
    },
    drillCount: 0,
    skillCount: 0,
    planCount: 0,
    dates: [startOfMonth, today],
    datePicker: false,
    customFilter: null,
    filters: [
      { label: 'Last 7 days', value: 'lastWeek' },
      { label: 'Last 4 weeks', value: 'lastFourWeeks' },
      { label: 'Last 3 months', value: 'lastThreeMonths' },
      { label: 'Last 12 months', value: 'lastTwelveMonths' },
      { label: 'Month to date', value: 'monthToDate' },
      { label: 'Quarter to date', value: 'quarterToDate' },
      { label: 'Year to date', value: 'yearToDate' },
    ],
    sortItems: [
      { label: 'Recent Update', value: 'recent' },
      { label: 'Oldest Update', value: 'oldest' },
      { label: 'Most Viewed', value: 'most' },
      { label: 'Least Viewed', value: 'least' },
      { label: 'User Favourites', value: 'popular' },
    ],
    items: [],
    type: 'drills',
    order: 'recent',
    projectId: '',
    totalSignedUp: 0,
    totalSignedIn: 0,
    totalActiveUserCount: 0,
    averageWeeklyUserCount: 0,
    exportSignUp: [],
    exportSignIn: [],
    userOptions: [
      {
        text: 'New User Sign Ups',
        label: 'All Sources',
        value: 'all',
      },
      {
        text: 'New User Sign Ups',
        label: 'USA Football Coach Planner App',
        value: 'signup',
      },
      {
        text: 'New User Sign Ups',
        label: 'USA Football Website',
        value: 'total',
      },
    ],
    customUsers: {
      text: 'New User Sign Ups',
      label: 'All Sources',
      value: 'all',
    },
    signupText: 'New User Sign Ups',
    teamData: [],
    fields: [
      'id',
      'name',
      'created',
      'coach',
      'player',
      'member',
      'ages',
      'gametype',
    ],
    userFields: ['coach', 'player', 'member'],
    userData: [],
    disableButton: false,
  }),
  computed: {
    ...mapState({
      orgId: (state) => state.user.orgId,
      customerId: (state) => state.user.customerId,
    }),
    dateRangeText() {
      return this.dates.join(' ~ ');
    },
    seriesContent() {
      return [this.drillCount, this.skillCount, this.planCount];
    },
    teamHeaders() {
      return {
        id: 'Team Id',
        name: 'Team Name',
        created: 'Date Created',
        coach: 'Number of Coaches',
        player: 'Number of Players',
        member: 'Number of Parents',
        ages: 'Age Group of the team',
        gametype: 'Game Type of the team',
      };
    },
    userHeaders() {
      return {
        coach: 'Coach',
        player: 'Player',
        member: 'Parents',
      };
    },
    isUsaFootball() {
      return this.orgId === 'uuwpknpjU3LSS2GO1ke7';
    },
  },
  watch: {
    orgId: function (value) {
      if (value !== 'uuwpknpjU3LSS2GO1ke7') {
        this.customUsers = null;
        this.signupText = 'New User Sign Ups';
      }
      this.fetchData();
    },
  },
  created() {
    this.fetchData();
    this.getVariables();
  },
  methods: {
    selectFilter() {
      let start = today;
      switch (this.customFilter) {
        case 'today':
          start = today;
          break;
        case 'lastWeek':
          start = moment().subtract(6, 'days').format('YYYY-MM-DD');
          break;
        case 'lastFourWeeks':
          start = moment().subtract(4, 'weeks').format('YYYY-MM-DD');
          break;
        case 'lastThreeMonths':
          start = moment().subtract(3, 'months').format('YYYY-MM-DD');
          break;
        case 'lastTwelveMonths':
          start = moment().subtract(12, 'months').format('YYYY-MM-DD');
          break;
        case 'monthToDate':
          start = moment().startOf('months').format('YYYY-MM-DD');
          break;
        case 'quarterToDate':
          start = moment().startOf('quarter').format('YYYY-MM-DD');
          break;
        case 'yearToDate':
          start = moment().startOf('years').format('YYYY-MM-DD');
          break;
      }
      this.dates = [start, today];
      this.fetchData();
    },
    filterByDate() {
      this.customFilter = null;
      this.$refs.menu.save(this.dates);
      this.fetchData();
    },
    async fetchData() {
      try {
        this.seriesSignedUp[0].data = [];
        this.optionsSignedUp.xaxis.categories = [];
        this.seriesSignIn[0].data = [];
        this.optionsSignIn.xaxis.categories = [];
        this.drillCount = 0;
        this.skillCount = 0;
        this.planCount = 0;

        const refOrg = await organizationsCollection.doc(this.orgId).get();
        this.projectId = refOrg.data().project_id;
        this.$store.dispatch('user/changeProject', refOrg.data().project_id);

        let filter = `?project=${this.projectId}`;
        if (this.dates.length > 0) {
          filter += `&start=${this.dates[0]}&end=${this.dates[1]}`;
        }

        const [rsSignIn, rsContent, rsActive] = await Promise.all([
          dashboardApi.get(`/dashboard/signed_in_users${filter}`),
          dashboardApi.get(`/dashboard/contents_count?org=${this.orgId}`),
          dashboardApi.get(`/dashboard/usa/active_users${filter}`),
        ]);

        let labelsSingIn = [];
        let seriesSignIn = [];
        let totalSignedIn = 0;
        let exportSignIn = [];
        if (Array.isArray(rsSignIn.data)) {
          rsSignIn.data.forEach((item, index) => {
            let count = parseInt(item._source.count);
            totalSignedIn += parseInt(item._source.count);
            labelsSingIn.push(item._source.createdTime);
            seriesSignIn.push(count);
            let obj = {
              day: index,
              one: count,
            };
            exportSignIn.push(obj);
          });
        }

        this.drillCount = rsContent.data.drills || 0;
        this.skillCount = rsContent.data.skills || 0;
        this.planCount = rsContent.data.plans || 0;

        this.seriesSignIn[0].data = seriesSignIn;
        this.optionsSignIn.xaxis.categories = labelsSingIn;

        this.totalSignedIn = totalSignedIn;
        this.exportSignIn = exportSignIn;

        const { distinct_users } = rsActive.data.aggregations;
        this.totalActiveUserCount =
          distinct_users.value || rsActive.data.active_count || 0;
        this.averageWeeklyUserCount =
          Math.round(rsActive.data.weekly_count) || 0;

        this.getSignup(filter);
        this.getItems(this.type);
        this.getUsaTeams();
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    async getItems(type) {
      try {
        this.type = type;
        let filter = `?org=${this.orgId}&order=${this.order}&project=${this.projectId}`;
        let rs = await dashboardApi.get(`/dashboard/${type}${filter}`);
        let items = [];
        for (const item of rs.data) {
          item._source.addedToCustomPlanCount = item._source[this.projectId]
            ? item._source[this.projectId].addedToCustomPlanCount
            : 0;
          item._source.favouriteCount = item._source[this.projectId]
            ? item._source[this.projectId].favouriteCount
            : 0;
          item._source.viewCount = item._source[this.projectId]
            ? item._source[this.projectId].viewCount
            : 0;
          items.push(item._source);
        }
        this.items = items;
      } catch (err) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${err.message}`,
        });
      }
    },
    async getSignup(filter) {
      let labels = [];
      let series = [];
      let totalSignedUp = 0;
      let exportSignUp = [];

      if (this.isUsaFootball) {
        const usaMap = new Map();
        if (['all', 'total'].includes(this.customUsers.value)) {
          const dates = await this.getTotalUsaUsers();
          totalSignedUp = dates.total;
          for (const [key, value] of dates.dateMap) {
            usaMap.set(key, value);
          }
        }

        if (['all', 'signup'].includes(this.customUsers.value)) {
          const signup = await dashboardApi.get(
            `/dashboard/signed_up_users${filter}`,
          );
          signup.data.hits.forEach((item, index) => {
            let count = parseInt(item._source.count);
            totalSignedUp += parseInt(item._source.count);
            usaMap.set(
              item._source.createdTime,
              usaMap.has(item._source.createdTime)
                ? usaMap.get(item._source.createdTime) + count
                : count,
            );
          });
        }

        const sortedByKey = new Map([...usaMap.entries()].sort());

        labels = [...Array.from(sortedByKey.keys())];
        series = [...Array.from(sortedByKey.values())];
      } else {
        let rsSignUp = await dashboardApi.get(
          `/dashboard/signed_up_users${filter}`,
        );
        rsSignUp.data.hits.forEach((item, index) => {
          let count = parseInt(item._source.count);
          totalSignedUp += parseInt(item._source.count);
          labels.push(item._source.createdTime);
          series.push(count);

          let obj = {
            day: index,
            one: count,
          };
          exportSignUp.push(obj);
        });
      }

      this.seriesSignedUp[0].data = series;
      this.optionsSignedUp.xaxis.categories = labels;
      this.totalSignedUp = totalSignedUp;
      this.exportSignUp = exportSignUp;
    },
    async getTotalUsaUsers() {
      try {
        const filter = `?start=${this.dates[0]}&end=${this.dates[1]}`;
        const result = await dashboardApi.get(
          `/dashboard/usa/new_sign_ups${filter}`,
        );
        const dateMap = new Map(Object.entries(result.data.dates));

        return { dateMap, total: result.data.total };
      } catch (error) {
        return { dateMap: new Map(), total: 0 };
      }
    },
    getUserData() {
      this.signupText = this.customUsers.text;
      this.fetchData();
    },
    async getUsaTeams() {
      if (!this.isUsaFootball) return;

      try {
        const filter = `?start=${this.dates[0]}&end=${this.dates[1]}`;
        const res = await dashboardApi.get(
          `/dashboard/usa/export_teams${filter}`,
        );
        this.getTeamData(res.data);
      } catch (error) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred`,
        });
      }
    },
    getTeamData(teams) {
      try {
        this.teamData = [];
        const ageLength = this.ages.length;
        const gameLength = this.types.length;
        teams.forEach((data) => {
          const teamObject = {
            id: data.id,
            name: data.teamName,
            created: data.created,
            ages: 'None',
            gametype: 'None',
            coach: 0,
            member: 0,
            player: 0,
          };

          if (data.ageGroup && ageLength > 0) {
            const age = this.ages.find((age) => age.key === data.ageGroup);
            if (age) teamObject.ages = String(age.value);
          }
          if (data.gameType && gameLength > 0) {
            const type = this.types.find((type) => type.key === data.gameType);
            if (type) teamObject.gametype = type.value;
          }
          if (data.users) {
            const users = new Map(Object.entries(data.users));
            for (let [_, value] of users) {
              const key =
                value === 'admin' || value === 'coach'
                  ? 'coach'
                  : value.toString().includes('Player')
                  ? 'player'
                  : value;
              teamObject[key] = teamObject[key] ? teamObject[key] + 1 : 1;
            }
          }
          this.teamData.push(teamObject);
        });
      } catch (error) {
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred : ${error.message}`,
        });
      }
    },
    async getUsaUsersData() {
      this.disableButton = true;
      try {
        const filter = `?start=${this.dates[0]}&end=${this.dates[1]}`;
        const ts = await dashboardApi.get(
          `/dashboard/usa/export_users${filter}`,
        );
        const blob = new Blob([ts.data], { type: 'text/csv;charset=utf-8' });
        this.disableButton = false;
        saveAs(blob, `User Data ${this.dates[0]} - ${this.dates[1]}.csv`);
      } catch (error) {
        this.disableButton = false;
        this.$store.dispatch('notification/add', {
          type: 'error',
          message: `An error occurred`,
        });
      }
    },
  },
};
</script>

<style scoped>
.custom-col1 {
  width: calc(100% - 70px);
}
.custom-col2 {
  width: 60px;
  margin-left: 10px;
}
.v-tabs .v-tabs-bar .v-tab.v-tab--active {
  color: #264583 !important;
}
.v-tabs .v-tabs-bar .v-tab {
  min-width: 90px !important;
  font-size: 18px !important;
}
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tab:first-child,
.v-application--is-ltr
  .v-tabs--align-with-title
  > .v-tabs-bar:not(.v-tabs-bar--show-arrows):not(.v-slide-group--is-overflowing)
  > .v-slide-group__wrapper
  > .v-tabs-bar__content
  > .v-tabs-slider-wrapper
  + .v-tab {
  margin-left: 0 !important;
}
.customTab .v-slide-group__content {
  position: relative !important;
  height: 38px;
}
.customTab .v-tabs-slider-wrapper {
  bottom: 0 !important;
}
</style>
