<template>
  <download-csv
    :data="jsonData"
    :labels="headers"
    :fields="fields"
    name="team-export.csv"
  >
    <v-btn
      v-if="useButtonText && buttonText"
      text
      class="transparent ma-0 pull-right"
      :disabled="jsonData.length === 0"
    >
      {{ buttonText }}
    </v-btn>

    <v-btn v-else icon class="transparent ma-0 pull-right">
      <v-icon color="grey darken-2">mdi-download-outline</v-icon>
    </v-btn>
  </download-csv>
</template>

<script>
export default {
  props: {
    jsonData: {
      type: Array,
      default: new Array(),
    },
    headers: {
      type: Object,
      default: () => {},
    },
    fields: {
      type: Array,
      required: true,
    },
    useButtonText: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
  data: () => ({}),
};
</script>
