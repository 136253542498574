<template>
  <download-csv
    :data="json_data"
    :labels="labels"
    :fields="fields"
    name="data-export.csv"
  >
    <v-btn
      v-if="useButtonText && buttonText"
      text
      class="transparent ma-0 pull-right"
    >
      {{ buttonText }}
    </v-btn>

    <v-btn v-else icon class="transparent ma-0 pull-right">
      <v-icon color="grey darken-2">mdi-tray-arrow-down</v-icon>
    </v-btn>
  </download-csv>
</template>

<script>
export default {
  props: {
    itemsNew: {
      type: Array,
      default: new Array(),
    },
    itemsActive: {
      type: Array,
      default: new Array(),
    },
    dates: {
      type: Array,
      required: true,
    },
    totalSignedUp: {
      type: Number,
      default: 0,
    },
    totalSignedIn: {
      type: Number,
      default: 0,
    },
    totalActiveUserCount: {
      type: Number,
      default: 0,
    },
    averageWeeklyUserCount: {
      type: Number,
      default: 0,
    },
    useButtonText: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      fields: ['day', 'one'],
      labels: {
        day: '# ----------------------------------------',
        one: '#----------',
      },
    };
  },
  computed: {
    startData() {
      return this.dates.length > 0 ? this.dates[0] : '';
    },
    endData() {
      return this.dates.length > 1 ? this.dates[1] : '';
    },
    json_data() {
      let headersNew = [
        {
          day: '# New user sign ups',
          one: '',
        },
        {
          day: `# Start date: ${this.startData}`,
          one: '',
        },
        {
          day: `# End date: ${this.endData}`,
          one: '',
        },
        {
          day: `# Total number of new user sign up over the time frame: ${this.totalSignedUp}`,
          one: '',
        },
        {
          day: 'The day',
          one: '1 day total new users',
        },
      ];
      let headersActive = [
        {
          day: '',
          one: '',
        },
        {
          day: '',
          one: '',
        },
        {
          day: '# Daily active users',
          one: '',
        },
        {
          day: `# Start date: ${this.startData}`,
          one: '',
        },
        {
          day: `# End date: ${this.endData}`,
          one: '',
        },
        // {
        //   day: `# Average weekly active users over the time frame: ${this.averageWeeklyUserCount}`,
        //   one: '',
        // },
        {
          day: `# Total number of unique active users over the time frame: ${this.totalActiveUserCount}`,
          one: '',
        },
        {
          day: 'The day',
          one: 'Daily active users',
        },
      ];
      return [
        ...headersNew,
        ...this.itemsNew,
        ...headersActive,
        ...this.itemsActive,
      ];
    },
  },
};
</script>
